import Request from '@/utils/request';

// 房源查询
export function getResourceList(data) {
  return Request('post', '/api/work/resourceList/newPageResource', data)
}
// 房源详情查询
export function getResourceInfo(data) {
  return Request('get', '/api/work/resourceDetail/readResource?resourceId=' + data)
}

// 房源根据管家id查管家信息
export function getManager(data) {
  return Request('post', '/api/work/resourceDetail/readSpUserDto', data)
}

// 房源根据房间id查租客信息
export function getTenantUser(data) {
  return Request('post', '/api/user/userMode/findTenantUserListById', data)
}

// 房源根据房间id查能耗记录
export function getResourceDevice(data) {
  return Request('post', '/api/smart/deviceInfo/findDeviceListByResource', data)
}

// 管家合闸
export function setOnMeter(data) {
  return Request('post', '/apg/meter/setOnMeter', data)
}

// 空置房源列表
export function getResourceVacantList(data) {
  return Request('post', '/apg/workBench/indexData/releasedList', data)
}

// 空置房数量
export function getResourceCount(data) {
  return Request('post', '/apg/workBench/indexData/releasedCube', data)
}