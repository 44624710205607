// 装修转换
export const fitmentCategoryObj = {
  1: '豪华装修',
  2: '精装修',
  3: '简装',
  4: '标准交付',
  5: '毛坯',
}

// 设备类型转换
export const deviceTypeObj = {
  11: '电表',
  12: '水表',
}