<template>
  <div id="back">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="home-o" size="24" />
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  props: ["title", "back"],
  methods: {
    onClickLeft() {
      if (this.back) {
        this.$router.push(this.back)
      } else {
        this.$router.go(-1);
      }
    },
    onClickRight() {
      this.$router.push('/home/homeInfo');
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/theme";

#back {
  .van-nav-bar {
    background: $theme-color;
  }

  .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: 0.18rem;
  }
}
</style>