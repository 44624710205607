<template>
  <div id="resourceInfo">
    <Back title="房源详情"></Back>
    <main>
      <!-- 房源轮播图 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <template v-if="resourceInfo.resourcePics">
          <van-swipe-item
            v-for="item in resourceInfo.resourcePics"
            :key="item.resourceId"
          >
            <van-image
              height="1.8rem"
              width="3.75rem"
              fit="fill"
              :src="item || imgSrc"
            />
          </van-swipe-item>
        </template>
        <template v-else>
          <van-swipe-item>
            <van-image
              height="1.8rem"
              width="3.75rem"
              fit="fill"
              :src="imgSrc"
            />
          </van-swipe-item>
        </template>
      </van-swipe>
      <!-- 房源描述 -->
      <van-cell-group>
        <van-cell
          :title="resourceInfo.unitName + '-' + resourceInfo.resourceName"
          :value="resourceInfo.propMgmtFee || '暂无报价'"
          :label="resourceInfo.detailAddress || '暂无位置信息'"
        />
      </van-cell-group>
      <!-- 房源信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>房源信息</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <van-row>
            <van-col span="12">
              <b>面积：</b>
              <span>{{ resourceInfo.area || "暂无" }}</span>
            </van-col>
            <van-col span="12">
              <b>楼层：</b>
              <span>{{ resourceInfo.floorName || "暂无" }}</span>
            </van-col>
            <van-col span="12">
              <b>装修：</b>
              <span>{{
                fitmentCategoryObj[resourceInfo.fitmentCategory] || "暂无"
              }}</span>
            </van-col>
            <van-col span="12">
              <b>能源套餐：</b>
              <span>{{ resourceInfo.energySetName || "暂无" }}</span>
            </van-col>
            <van-col span="12">
              <b>管家：</b>
              <span>{{ managerUser.fullName || "暂无" }}</span>
            </van-col>
            <van-col span="12">
              <b>手机号：</b>
              <span class="theme-color" @click="tel(managerUser.mobile)">{{
                managerUser.mobile || "暂无"
              }}</span>
            </van-col>
          </van-row>
        </div>
      </section>
      <!-- 租客信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>租客信息</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <template v-if="tenantUser.length">
            <van-cell-group>
              <van-cell
                v-for="item in tenantUser"
                :key="item.tenantId"
                :title="item.fullName"
                :value="item.mobile"
                @click="tel(item.mobile)"
              />
            </van-cell-group>
          </template>
          <template v-else>
            <van-cell-group>
              <van-cell title="暂无租客" value="" />
            </van-cell-group>
          </template>
        </div>
      </section>
      <!-- 房源设备 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>房源设备</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <template v-if="deviceList.length">
            <van-cell-group>
              <van-cell
                center
                v-for="item in deviceList"
                :key="item.deviceId"
                :title="
                  '【' + deviceTypeObj[item.deviceType] + '】' + item.macAddr
                "
              >
                <template #right-icon>
                  <van-button
                    type="info"
                    size="small"
                    @click="onSwitch(item)"
                    v-if="item.online"
                    >合闸</van-button
                  >
                  <van-button v-else color="#999999">设备离线</van-button>
                </template>
              </van-cell>
            </van-cell-group>
          </template>
          <template v-else>
            <van-cell-group>
              <van-cell title="暂无能耗记录" value="" />
            </van-cell-group>
          </template>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import {
  getResourceInfo,
  getManager,
  getTenantUser,
  getResourceDevice,
  setOnMeter,
} from "@/api/resource/resource";
import { fitmentCategoryObj, deviceTypeObj } from "@/db/resource";
import { Toast } from "vant";
export default {
  components: { Back },
  data() {
    return {
      imgSrc: require("../../assets/images/resource/house.png"),
      resourceInfo: "", //房源详细
      fitmentCategoryObj, //装修转换对象
      deviceTypeObj, //设备类型转换
      managerUser: "", //管家信息
      tenantUser: "", //租客信息
      deviceList: "", //能耗记录
    };
  },
  created() {
    this.getResourceInfo();
  },
  methods: {
    // 【请求】房源详情
    getResourceInfo() {
      let _this = this;
      let resourceId = this.$route.query.resourceId;
      getResourceInfo(resourceId).then((res) => {
        if (res.data) {
          this.resourceInfo = res.data;
          if (res.data.managerUserId) {
            _this.getManager(res.data.managerUserId);
          }
          if (res.data.resourceId) {
            _this.getTenantUser(res.data.resourceId);
            _this.getResourceDevice(res.data.resourceId);
          }
        }
      });
    },
    // 【请求】管家信息
    getManager(managerUserId) {
      let data = {
        spId: 2,
        userId: managerUserId,
      };
      getManager(data).then((res) => {
        if (res.data) {
          this.managerUser = res.data;
        }
      });
    },
    // 【请求】租客信息
    getTenantUser(resourceId) {
      let data = {
        pageNo: 1,
        resourceId,
      };
      getTenantUser(data).then((res) => {
        if (res.data) {
          this.tenantUser = res.data.content;
        }
      });
    },
    // 【请求】能耗记录
    getResourceDevice(resourceId) {
      let data = {
        resourceId,
      };
      getResourceDevice(data).then((res) => {
        if (res.data) {
          this.deviceList = res.data.filter(
            (i) => i.deviceType == 11 || i.deviceType == 12
          );
        }
      });
    },
    // 【监听】电话拨打
    tel(tel) {
      window.location.href = "tel://" + tel;
    },
    // 【监听】合闸
    onSwitch(item) {
      let data = {
        macAddr: item.macAddr,
      };
      setOnMeter(data).then((res) => {
        if (res.success) {
          Toast("合闸成功");
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#resourceInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 0.2rem;
  line-height: 1.8rem;
  text-align: center;
  height: 1.8rem;
}
// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
}
</style>